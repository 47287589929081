import ApplicationController from "./application_controller"
import { useResize } from "stimulus-use"

export default class extends ApplicationController {
  containerAspectRatioVar = "--container-aspect-ratio"
  aspectRatioThreshold = 0.2

  static values = {
    autoFit: Boolean,
    aspectRatio: Number,
  }

  static classes = ["contain", "cover"]

  connect() {
    this.autoFit()
  }

  autoFit() {
    if (!this.autoFitValue) return
    useResize(this)
    this.updateFit()
  }

  resize() {
    this.updateFit()
  }

  updateFit() {
    const diff = Math.abs(this.containerAspectRatio - this.aspectRatioValue)
    const cover = diff < this.aspectRatioThreshold
    this.element.classList.toggle(this.coverClass, cover)
    this.element.classList.toggle(this.containClass, !cover)
  }

  get container() {
    return this.element.closest(".Works")
  }

  get containerAspectRatio() {
    if (!this.container) return
    return parseFloat(this.container.dataset.worksComponentAspectRatioValue)
  }
}
