import ApplicationController from "../application_controller"
import { useDispatch } from "stimulus-use"

export default class extends ApplicationController {
  static targets = ["slide"]
  static values = { index: Number }

  initialize() {
    useDispatch(this)
  }

  connect() {
    this.element["slideshowController"] = this
  }

  select(id) {
    const slide = this.slideTargets.find(
      (s) => s.dataset.workImageAttachmentId === id,
    )
    if (!slide) {
      console.log(`Could not find a slide for id ${id}`)
      return
    }
    const index = this.slideTargets.indexOf(slide)
    this.indexValue = index
  }

  next() {
    if (this.indexValue === this.slideTargets.length - 1) {
      this.indexValue = 0
    } else {
      this.indexValue++
    }
  }

  previous() {
    this.indexValue--
  }

  indexValueChanged() {
    useDispatch(this)
    this.showCurrentSlide()
    this.dispatch("select", {
      workImageAttachmentId: this.currentWorkImageAttachmentId,
      textSourceId: this.currentTextSourceId,
    })
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      element.hidden = index != this.indexValue
    })
  }

  get currentWorkImageAttachmentId() {
    return this.currentSlide.dataset.workImageAttachmentId
  }

  get currentTextSourceId() {
    return this.currentSlide.dataset.textSourceId
  }

  get currentSlide() {
    return this.slideTargets[this.indexValue]
  }
}
