import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["more", "toggle"]
  static values = {
    open: Boolean,
  }

  connect() {
    this.toggleTarget.hidden = false
  }

  toggle() {
    this.toggleTarget.blur()
    this.openValue = !this.openValue
  }

  openValueChanged() {
    this.moreTarget.hidden = !this.openValue
    this.toggleTarget.setAttribute("aria-expanded", this.openValue)
  }
}
