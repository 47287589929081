require("@rails/ujs").start()
require("turbolinks").start()

import "@asgerb/apparatus/package/dist/application.js"

import "controllers"

import "@github/tab-container-element"
import "blurhash-img"

// require.context("../images", true)
// require.context("../icons", true)
