import ApplicationController from "./application_controller"
import { useDispatch, useResize } from "stimulus-use"

export default class extends ApplicationController {
  aspectRatioVar = "--container-aspect-ratio"

  static targets = [
    "slideshow",
    "index",
    "stage",
    "textSourceReference",
    "workImageAttachmentReference",
  ]
  static classes = ["stageOverlaid"]
  static values = {
    currentWorkImageAttachmentId: String,
    currentTextSourceId: String,
    aspectRatio: Number,
  }

  connect() {
    useDispatch(this)
    useResize(this)
    this.updateAspectRatio()
    this.toggleStageOverlaidClass()
  }

  resize({ width, height }) {
    this.updateAspectRatio(width, height)
  }

  updateAspectRatio(
    width = this.element.clientWidth,
    height = this.element.clientHeight
  ) {
    this.aspectRatioValue = width / height

    if (this.aspectRatioValue != this.previousAspectRatio) {
      this.element.style.setProperty(this.aspectRatioVar, `${height}/${width}`)
      this.previousAspectRatio = this.aspectRatioValue
    }
  }

  select(event) {
    if (!this.slideshowController) return
    const imageId = event.currentTarget.dataset.imageId
    this.slideshowController.select(imageId)
  }

  slideSelected({ detail: { workImageAttachmentId, textSourceId } }) {
    this.currentWorkImageAttachmentIdValue = workImageAttachmentId
    this.currentTextSourceIdValue = textSourceId
  }

  toggleIndex() {
    this.indexTarget.hidden = !this.indexTarget.hidden
    this.toggleStageOverlaidClass()
  }

  closeIndex() {
    this.indexTarget.hidden = true
    this.toggleStageOverlaidClass()
  }

  resetIndexScroll() {
    this.indexTarget.scrollTop = 0
  }

  toggleStageOverlaidClass() {
    this.stageTarget.classList.toggle(
      this.stageOverlaidClass,
      !this.indexTarget.hidden
    )
  }

  currentWorkImageAttachmentIdValueChanged() {
    if (!this.currentWorkImageAttachmentIdValue) return
    this.workImageAttachmentReferenceTargets.forEach((reference) => {
      reference.ariaSelected =
        reference.dataset.workImageAttachmentId ===
        this.currentWorkImageAttachmentIdValue
    })
  }

  currentTextSourceIdValueChanged() {
    if (!this.currentTextSourceIdValue) return
    this.textSourceReferenceTargets.forEach((reference) => {
      reference.ariaSelected =
        reference.dataset.textSourceId === this.currentTextSourceIdValue
    })
  }

  scrollToWorkImageAttachmentReference(event) {
    const index = event.target.dataset.index
    const reference = this.getWorkImageAttachmentReferenceWithIndex(index)
    if (!reference) return
    reference.scrollIntoView()
  }

  scrollToTextSourceReference(event) {
    const index = event.target.dataset.index
    const reference = this.getTextSourceReferenceWithIndex(index)
    if (!reference) return
    reference.scrollIntoView()
  }

  getWorkImageAttachmentReferenceWithIndex(index) {
    return this.workImageAttachmentReferenceTargets.filter((target) => {
      return target.dataset.index === index
    })[0]
  }

  getTextSourceReferenceWithIndex(index) {
    return this.textSourceReferenceTargets.filter((target) => {
      return target.dataset.index === index
    })[0]
  }

  get slideshowController() {
    if (!this.hasSlideshowTarget) return
    return this.slideshowTarget.slideshowController
  }
}
