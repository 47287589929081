import { Controller } from "stimulus"

export default class extends Controller {
  customVar = "--viewport-ratio"

  connect() {
    this.doc = document.documentElement
    this.update()
  }

  update() {
    requestAnimationFrame(() => {
      const clientHeight = this.doc.clientHeight
      const clientWidth = this.doc.clientWidth
      const ratio = clientHeight / clientWidth
      if (ratio === this.previousRatio) return
      this.doc.style.setProperty(this.customVar, ratio)
      this.previousRatio = ratio
    })
  }
}
